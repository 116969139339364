var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length
    ? _c("div", { staticClass: "cart-crosssell", attrs: { type: "" } }, [
        _c("h3", { staticClass: "cart-crosssell__title" }, [
          _vm._v("\n        Do Twojego zamówienia możesz dodać\n    ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "items", staticClass: "cart-crosssell__items" },
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "cart-crosssell__item-wrapper",
                attrs: {
                  "data-gtm-product-id": _vm.type + "-popup-" + item.gtm.id,
                  "gtm-element": "product-item"
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "cart-crosssell__item",
                    attrs: { href: item.url, tabindex: "0" }
                  },
                  [
                    item.img
                      ? _c("div", { staticClass: "cart-crosssell__item-img" }, [
                          _c("img", {
                            attrs: {
                              src: item.img,
                              alt: item.name ? item.name : ""
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.name
                      ? _c("p", { staticClass: "cart-crosssell__item-name" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.name) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.price
                      ? _c("div", {
                          staticClass: "cart-crosssell__item-price",
                          domProps: { innerHTML: _vm._s(item.price) }
                        })
                      : _vm._e()
                  ]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (_vm.isMobile && _vm.items.length > 1) ||
                  (_vm.isDesktop && _vm.items.length > 3),
                expression:
                  "\n            (isMobile && items.length > 1) ||\n            (isDesktop && items.length > 3)\n        "
              }
            ],
            ref: "arrows",
            staticClass: "cart-crosssell__switcher"
          },
          [
            _c(
              "button",
              {
                ref: "arrowLeft",
                staticClass: "btn btn--clear",
                attrs: {
                  "aria-label": "Poprzedni produkt",
                  title: "Poprzedni produkt"
                }
              },
              [
                _c("span", {
                  staticStyle: { width: "17px", height: "32px" },
                  attrs: { "data-icon": "arrow-left" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "arrowRight",
                staticClass: "btn btn--clear",
                attrs: {
                  "aria-label": "Kolejny produkt",
                  title: "Kolejny produkt"
                }
              },
              [
                _c("span", {
                  staticStyle: { width: "17px", height: "32px" },
                  attrs: { "data-icon": "arrow-right" }
                })
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }