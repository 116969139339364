<template>
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="items && items.length" class="cart-crosssell" type>
        <h3 class="cart-crosssell__title">
            Do Twojego zamówienia możesz dodać
        </h3>
        <div ref="items" class="cart-crosssell__items">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="cart-crosssell__item-wrapper"
                :data-gtm-product-id="`${type}-popup-${item.gtm.id}`"
                gtm-element="product-item"
            >
                <a :href="item.url" class="cart-crosssell__item" tabindex="0">
                    <div v-if="item.img" class="cart-crosssell__item-img">
                        <img
                            :src="item.img"
                            :alt="item.name ? item.name : ''"
                        />
                    </div>
                    <p v-if="item.name" class="cart-crosssell__item-name">
                        {{ item.name }}
                    </p>
                    <div
                        v-if="item.price"
                        class="cart-crosssell__item-price"
                        v-html="item.price"
                    />
                </a>
            </div>
        </div>

        <div
            v-show="
                (isMobile && items.length > 1) ||
                (isDesktop && items.length > 3)
            "
            ref="arrows"
            class="cart-crosssell__switcher"
        >
            <button
                ref="arrowLeft"
                aria-label="Poprzedni produkt"
                title="Poprzedni produkt"
                class="btn btn--clear"
            >
                <span
                    data-icon="arrow-left"
                    style="width: 17px; height: 32px"
                />
            </button>

            <button
                ref="arrowRight"
                aria-label="Kolejny produkt"
                title="Kolejny produkt"
                class="btn btn--clear"
            >
                <span
                    data-icon="arrow-right"
                    style="width: 17px; height: 32px"
                />
            </button>
        </div>
    </div>
</template>

<script>
import resizeObserver from '../utils/resizeObserver.js'
import handleSliderArrows from '@js/utils/handleSliderArrows.js'
import icons from '@js/app/icons.js'

export default {
    mixins: [resizeObserver],
    props: {
        // array of crosssell products
        items: {
            type: Array,
            default: () => []
        },
        // type of products (crosssell or upsell)
        type: {
            type: String,
            default: 'crosssell'
        }
    },
    data() {
        return {
            slider: null
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this?.items?.length > 1) {
                this.initSlider()
                icons(this.$refs.arrows)
            }

            if (window?.GoogleAnalyticsUniversal) {
                let impressions = []
                this.items.forEach((item) => {
                    impressions.push(item.gtm)
                    window.staticImpressions[
                        `${this.type}-popup-${item.gtm.id}`
                    ] = item.gtm
                })
                window.GoogleAnalyticsUniversal.prototype.pushImpressions(
                    impressions
                )
                window.GoogleAnalyticsUniversal.prototype.listenImpressionClick(
                    this.$refs.items
                )
            }
        })
    },
    methods: {
        initSlider() {
            this.slider = new window.Glider(this.$refs.items, {
                slidesToShow: 1,
                slidesToScroll: 1,
                scrollLock: true,
                rewind: true,
                draggable: true,
                responsive: [
                    {
                        breakpoint: 419,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 585,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ],
                arrows: {
                    prev: this.$refs.arrowLeft,
                    next: this.$refs.arrowRight
                }
            })
            handleSliderArrows(this.slider)
        },
        destroyCrossSellSlider() {
            if (this.slider) {
                this.slider.destroy()
            }
        }
    }
}
</script>

<style lang="scss">
.t-general .cart-crosssell {
    background-color: #f4f4f4;
    padding-top: 25px;
    width: 100%;
    max-width: 100%;
    position: relative;
    border-radius: 12px;

    &__title {
        font-size: 17px;
        margin-bottom: 30px;
        font-weight: 600;
        text-align: center;
        text-transform: none;
    }

    &__items {
        width: inherit;
        max-width: inherit;
        padding-bottom: 20px;
        width: 90%;
        margin: 0 auto;
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        margin: 10px 15px;
        text-align: center;
        align-self: center;

        &-price {
            .price {
                color: $blue-dianne;
                font-weight: 600;
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        &-img {
            position: relative;
            margin-bottom: 15px;

            img {
                height: 120px;
                max-height: 100px;
                margin: 0 auto;
            }
        }

        &-name {
            margin: 0;
            font-size: 13px;
            color: #000;
            line-height: 1.4;
            font-weight: 600;
            overflow: hidden;
            height: 57px;
        }
    }

    &__switcher {
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;

        button {
            visibility: hidden;
        }
    }
}
</style>
